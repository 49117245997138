import React, { useEffect, useState } from 'react';
import { Toaster, toast } from 'sonner';
import DashboardLayout from '../components/DashboardLayout';
import DropdownMenuHC from '../components/Menus/DropdownHC';
import { usePublishUpdate } from '../hooks/useUpdates';
import { useFetchVersions } from '../hooks/useVersions';
import { HardwaresApi } from '../lib/hardwares.api';

const OtaUpdates = () => {
  const { data: versions } = useFetchVersions()
  const { mutate } = usePublishUpdate()
  const [selectedHardwares, setSelectedHardwares] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState([]);
  const [hardwares, setHardwares] = useState([]);
  const options = hardwares?.map((item) => ({ name: item.hwid }));
  const versionsList = versions?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item) => ({ name: item.name }));
  const selectedVersionForUpdate = versions && versions?.find((item) => item.name === selectedVersion);
  const isUploadEnabled = selectedHardwares.length > 0 && selectedVersion.length > 0;
  const disabledButtonStyles = isUploadEnabled ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
  useEffect(() => {
    const fetchHardwares = async () => {
      try {
        const responseData = await HardwaresApi.fetchHardwares();
        setHardwares(responseData);
      } catch (error) {
        console.error("Error fetching hardwares:", error);
        throw error
      }
    };

    fetchHardwares();
  }, []);

  const handleUploadToServer = () => {
    mutate(
      { versionId: selectedVersionForUpdate?._id || '', hardwares: selectedHardwares },
      {
        onSuccess: () => {
          setSelectedHardwares([]);
          setSelectedVersion([]);
          toast.success('Updates Deployed Successfully');
        },
        onError: (error) => {
          toast.error(error.message);
        }
      }
    );
  };


  return (
    <div>
      <Toaster position="top-right" richColors />
      <DashboardLayout>
        <div className="w-full flex flex-wrap justify-start items-center p-5 flex-col h-screen divide-y">
          <h1 className='text-3xl font-medium text-gray-800 pb-4'>Over The Air Updates</h1>
          <div className="flex w-full h-20 justify-center items-center">
            <div className='w-3/5 flex justify-between'>
              <span className="w-76 min-w-[20rem]">
                <DropdownMenuHC
                  dropdownOptions={options}
                  selector={selectedHardwares}
                  setSelector={setSelectedHardwares}
                  arrowDirectionUp={false}
                  type="checkbox"
                  placeholder='Choose Single or Multiple Hardwares'
                />
              </span>
              <span className="w-72 ">
                <DropdownMenuHC
                  dropdownOptions={versionsList}
                  selector={selectedVersion}
                  setSelector={setSelectedVersion}
                  arrowDirectionUp={false}
                  placeholder='Choose a Version'
                />
              </span>
            </div>
          </div>
          <div className="w-full gap-5 justify-center py-4 flex h-4/5">
            <div className="h-full w-3/5 py-4 justify-around flex flex-col ">
              <div className='flex w-full h-full justify-between '>
                <div className="h-full w-2/5 border rounded-xl py-4 divide-y">
                  <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">Selected Hardwares</h1>
                  <div className="p-2 flex flex-col gap-2 overflow-y-auto max-h-[95%]">
                    {selectedHardwares.map((item, index) => (
                      <div
                        key={index}
                        className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2"
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="h-full w-2/5 border rounded-xl py-4 divide-y">
                  <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">Selected Version</h1>
                  <div className="p-2 flex flex-col gap-2 ">
                    {selectedVersion.length > 0 ?
                      <div
                        className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2"
                      >
                        {selectedVersion}
                      </div> : ('')}

                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center items-end h-20">
                <button
                  className={`px-4 py-2 rounded-md text-white font-semibold ${disabledButtonStyles}`}
                  onClick={handleUploadToServer}
                  disabled={!isUploadEnabled}
                >
                  Post Updates to Server
                </button>
              </div>
            </div>
          </div>

        </div>
      </DashboardLayout>
    </div>
  );
};

export default OtaUpdates;
