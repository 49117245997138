
import { createSlice } from '@reduxjs/toolkit';
import { NOTIFY_TYPE } from '../utils/constants';

const initialState = {
  notifications: {
    messages: [],
    type: null,
  },
  appNotifications: [
    {
      text: '',
      variant: NOTIFY_TYPE.Warning,
      response: {
        closed: false,
        closedAt: null,
      },
    },
  ],
};
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action) =>
      (state = { ...state, notifications: action.payload }),
    removeNotifications: (state) => {
      state.notifications = initialState.notifications;
    },
    setAppNotifications: (state, action) => {
      const updatedCompanyNotifications = [...(state.appNotifications || []), action.payload];
      state.appNotifications = updatedCompanyNotifications;
    },
    removeFirstMessage: (state) => {
      state.notifications.messages.shift();
    },
  },
});

export const {
  setNotifications,
  removeNotifications,
  setAppNotifications,
  removeFirstMessage
} = notificationsSlice.actions;

export default notificationsSlice.reducer;