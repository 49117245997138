import { Menu, Transition } from "@headlessui/react";
import { PlusIcon, UserIcon, WrenchIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getValuesFromParams } from "../../utils/helpers";

const mainOptions = ["Hardware", "Technicians"];
const mainOptionIcons = {
  Hardware: <WrenchIcon className="h-6 w-6 mr-2 text-black" />,
  Technicians: <UserIcon className="h-6 w-6 mr-2 text-black" />,
};

const HardwareAndTechFilter = ({
  hardwareDropdownOptions = [],
  technicianDropdownOptions = [],
}) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedHardware, setSelectedHardware] = useState(getValuesFromParams(searchParams, "hwid"));
  const [selectedTechnician, setSelectedTechnician] = useState(getValuesFromParams(searchParams, "technicianId"));
  const [activeSubFilters, setActiveSubFilters] = useState({
    Hardware: false,
    Technicians: false,
  });


  // Toggle main filters to render sub-options list
  const toggleSubFilter = (option) => {
    setActiveSubFilters((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  // Handle sub-option toggling
  const toggleSelection = (option, type) => {
    const isSelected = (selection) => selection.includes(option);
    const updateSelection = (selections) =>
      isSelected(selections) ? selections.filter((item) => item !== option) : [...selections, option];

    if (type === "Hardware") {
      setSelectedHardware(updateSelection(selectedHardware));
    } else if (type === "Technicians") {
      setSelectedTechnician(updateSelection(selectedTechnician));
    }
  };

  // Returns the list of all sub-options
  const getSubOptions = (option) => (option === "Hardware" ? hardwareDropdownOptions : technicianDropdownOptions);

  // Returns the list of selected options
  const getSelectedItems = (option) => (option === "Hardware" ? selectedHardware : selectedTechnician);

  // Event handler for managing sub-options
  const handleFilterClick = (event, option) => {
    event.preventDefault();
    toggleSubFilter(option);
  };

  //Handles apply button
  const handleApplyFilters = () => {
    if (selectedHardware.length < 0 || selectedTechnician.length < 0) return
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams.entries()),
      hwid: selectedHardware.join(","),
      technicianId: selectedTechnician.join(","),
    }));
  }

  // Render sub-options list
  const DisplaySubMenu = ({ option, subOptions, selectedItems }) => {
    if (!activeSubFilters[option]) return null;
    return (
      <div className="pl-8 py-2 max-h-48 overflow-y-auto">
        {subOptions?.map((subOption) => (
          <div key={subOption} className="flex items-center mb-1">
            <input
              id={`sub-option-${option}-${subOption}`}
              type="checkbox"
              checked={selectedItems.includes(subOption)}
              onChange={() => toggleSelection(subOption, option)}
              className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor={`sub-option-${option}-${subOption}`} className="text-gray-700 text-sm">
              {subOption}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const FiltersList = () => {
    return mainOptions.map((option) => (
      <div key={option} className="mb-2">
        <button
          onClick={(event) => handleFilterClick(event, option)}
          className="w-full flex items-center px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 rounded-md"
          type="button"
        >
          {mainOptionIcons[option]}
          {option}
        </button>
        <DisplaySubMenu
          option={option}
          subOptions={getSubOptions(option)}
          selectedItems={getSelectedItems(option)}
        />
      </div>
    ));
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center items-center w-full rounded-md border border-dashed border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
        Add Filter
        <PlusIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 mt-2 w-60 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-50">
          <div className="p-4">
            <FiltersList />
          </div>
          <div className="px-4 py-2">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleApplyFilters}
                  className={`${active ? "bg-blue-700" : "bg-blue-600"} w-full text-white px-3 py-2 rounded-md focus:outline-none`}
                  type="button"
                >
                  Apply
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export { HardwareAndTechFilter };
