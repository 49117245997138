import React, { useEffect, useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import AddHardware from '../components/hardware/addHardware';
import { ListHardwares } from '../components/hardware/renderCards';
import { Loader } from '../components/Loader/Loader';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { HardwaresApi } from '../lib/hardwares.api';

export function Hardware() {
    const [selectedHardware, setSelectedHardware] = useState({})
    const [hardwaresData, setHardwaresData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        fetchHardwares();
    }, []);

    const fetchHardwares = async () => {
        setIsLoading(true)
        try {
            const responseData = await HardwaresApi.fetchHardwares();
            setHardwaresData(responseData)
        } catch (error) {
            setError('Error fetching data' || error)
        } finally {
            setIsLoading(false)
        }
    };

    const deleteSelectedHardwares = async () => {
        const { _id } = selectedHardware
        try {
            await HardwaresApi.deleteHardware(_id);
            fetchHardwares();
            setDeleteModal(false)
        } catch (error) {
            console.error(error)
        }
    }

    const DisplayLoader = () => {
        if (isLoading) return <Loader />
    }

    const DisplayError = () => {
        if (error.length) return <ErrorAlert errorMessages={error} />
    }

    return (
        <DashboardLayout>
            <DisplayLoader />
            <DisplayError />
            <AddHardware
                refetch={fetchHardwares}
            />
            <ListHardwares
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                editModal={editModal}
                setEditModal={setEditModal}
                hardwaresData={hardwaresData}
                deleteSelected={deleteSelectedHardwares}
                selectedHardware={selectedHardware}
                setSelectedHardware={setSelectedHardware}
                reFetch={fetchHardwares}
            />
        </DashboardLayout>
    );
}
