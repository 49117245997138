import { ClearFiltersButton } from '../../utils/shared/ClearFiltersButton';
import { DateRangeFilter } from '../../utils/shared/DateRangeFilter';
import { AppliedFilters } from './AppliedFilters';

import { HardwareAndTechFilter } from './HardwareAndTechFilter';
import { SearchBar } from './SearchBar';

const SearchFilters = ({ hardwares, technicians, handleSearchParams }) => {

  return (
    <>
      <div className="flex flex-wrap py-2 gap-3 items-center justify-end">
        <ClearFiltersButton />
        <SearchBar handleSearchParams={handleSearchParams} />
        <HardwareAndTechFilter
          hardwareDropdownOptions={hardwares.map(hw => hw.name)}
          technicianDropdownOptions={technicians.map(tech => tech.technicianId.username)}
        />
        <DateRangeFilter />
      </div>
      <AppliedFilters
        hardwareDropdownOptions={hardwares.map(hw => hw.name)}
        technicianDropdownOptions={technicians.map(tech => tech.technicianId.username)}
      />
    </>
  );
};

export default SearchFilters;
