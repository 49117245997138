import * as React from 'react';
import { useEffect, useState } from 'react';
import AddChemical from '../components/Chemicals/addChemical.jsx';
import ListChemicals from '../components/Chemicals/listChemicals.jsx';
import DashboardLayout from '../components/DashboardLayout';
import { Loader } from '../components/Loader/Loader.jsx';
import { useApiOperation } from '../hooks/useApiOperation.js';
import { ChemicalsApi } from '../lib/chemicals.api.js';

const dropdownOptions = [
  { name: 'Ounces per gallon' },
  { name: 'Grams per gallon' },
  { name: 'Mililiter per gallon' },
];

export default function Dashboard() {
  const measurementUnit = dropdownOptions[0].name
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const [selectedChemical, setSelectedChemical] = useState({})
  const [chemicalsData, setChemicalsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  useEffect(() => {
    fetchChemicals();
  }, []);

  const fetchChemicals = async () => {
    try {
      startApiOperation()
      const responseData = await ChemicalsApi.fetchChemicals();
      terminateApiOperation()
      setChemicalsData(responseData)
    } catch (error) {
      terminateApiOperation([
        'Something went wrong while fetching hardwares. Please try again later',
      ]);
    }
  };

  const deleteSelectedChemical = async () => {
    const { _id } = selectedChemical
    try {
      await ChemicalsApi.deleteChemical(_id);
      fetchChemicals();
      setDeleteModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const DisplayLoader = () => {
    if (apiLoading) return <Loader />
  }

  return (
    <DashboardLayout>
      <DisplayLoader />
      <AddChemical
        refetch={fetchChemicals}
        dropdownOptions={dropdownOptions}
        measurementUnit={measurementUnit}
      />
      <ListChemicals
        chemicalsData={chemicalsData}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteSelected={deleteSelectedChemical}
        editModal={editModal}
        setEditModal={setEditModal}
        reFetch={fetchChemicals}
        selectedChemical={selectedChemical}
        setSelectedChemical={setSelectedChemical}
        dropdownOptions={dropdownOptions}
      />
    </DashboardLayout>
  );
}
