import { useUser } from '../../hooks/useAuth.js'
import Card from '../Card/Card.jsx'

const CardList = ({ data, onEditClick, onDeleteClick }) => {
    const { data: userData } = useUser()

    const DisplayHardwares = () => {
        return data?.map(variant =>
            <div key={variant._id}>
                <Card
                    name={variant.name}
                    hwid={variant.hwid}
                    description={variant.description}
                    onEditClick={() => onEditClick({ ...variant, defaultHardware: variant._id === userData?.user?.defaultHardware })}
                    onDeleteClick={() => onDeleteClick(variant)}
                    marker={variant._id === userData?.user?.defaultHardware}
                />
            </div>
        )
    }

    return (
        <div className='grid grid-cols-1 p-5 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-[25px] gap-y-6'>
            <DisplayHardwares />
        </div>
    )
}

export { CardList }
