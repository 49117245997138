import { ButtonSizes, ButtonTypeVariant, ButtonVariants } from '../constants';
import { mergeClasses } from '../helpers';

const BUTTON_SIZE_CONFIG = {
  [ButtonSizes.EXTRASMALL]: 'max-w-xs',
  [ButtonSizes.SMALL]: 'max-w-sm',
  [ButtonSizes.MEDIUM]: 'max-w-md',
  [ButtonSizes.LARGE]: 'max-w-lg',
  [ButtonSizes.EXTRALARGE]: 'max-w-xl',
  [ButtonSizes.FULL]: 'w-full',
  [ButtonSizes.FIT]: 'w-fit',
};

const BUTTON_VARIANTS_CONFIG = {
  [ButtonVariants.PRIMARY]: 'bg-primary text-white hover:bg-green-600 focus:border-primary ',
  [ButtonVariants.SECONDARY]: 'border border-gray-300 hover:bg-secondary',
  [ButtonVariants.DANGER]: 'bg-red-500 text-white hover:bg-red-600',
  [ButtonVariants.DISABLED]: 'bg-gray-500 text-white hover:bg-gray-600',
  [ButtonVariants.TABS]: 'rounded-md px-3 py-2 border-none',
  [ButtonVariants.LINK]: 'p-0 text-black bg-transparent border-none shadow-none',
  [ButtonVariants.DETAILS]:
    'border border-gray-300 text-gray-900 -my-2 border hover:bg-white !rounded-lg !px-12 py-1.5 -ml-2',
  [ButtonVariants.CLIPBOARD]: 'border border-gray-300 hover:bg-secondary !px-0  !py-0 ',
};

const DEFAULT_BUTTON_STYLES =
  'text-sm inline-flex items-center justify-center whitespace-nowrap focus:ring-0 focus:outline-0 rounded-lg transition-all cursor-pointer leading-5 focus-visible:outline-primary w-fit items-center gap-x-2 rounded-md px-4 py-2 disabled:opacity-50 disabled:pointer-events-none text-sm shadow-sm transition-all ';

const Button = ({
  icon,
  title,
  onClick,
  btnKey,
  disabled,
  buttonStyles,
  textStyles,
  type = ButtonTypeVariant.BUTTON,
  isLoading,
  styles,
  variant = ButtonVariants.PRIMARY,
  size = ButtonSizes.FULL,
  ...props
}) => {
  const defaultButtonClasses = mergeClasses(
    DEFAULT_BUTTON_STYLES,
    buttonStyles,
    isLoading && 'cursor-wait bg-gray-300'
  );

  const mergedTextClasses = mergeClasses(textStyles);
  const buttonVariant = BUTTON_VARIANTS_CONFIG[variant];
  const buttonSize = BUTTON_SIZE_CONFIG[size];

  const mergedButtonClasses = mergeClasses(buttonVariant, defaultButtonClasses, buttonSize);

  const RenderButtonContent = () => {
    if (!isLoading) {
      const DisplayTitle = () => {
        if (!title) return null;
        return <p className={mergedTextClasses}>{title}</p>;
      };
      return (
        <>
          {icon}
          <DisplayTitle />
        </>
      );
    }
  };

  return (
    <button
      key={btnKey}
      className={mergedButtonClasses}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
      style={styles}
      {...props}
    >
      <RenderButtonContent />
    </button>
  );
};

export { Button };
