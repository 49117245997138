import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { setAddress, setMapCenter, setStep } from '../../features/jobDetailsSlice';
import { setJobInProgress } from '../../features/jobInProgressSlice';
import { useApiOperation } from '../../hooks/useApiOperation';
import { useUser } from '../../hooks/useAuth';
import { JobsApi } from '../../lib/jobs.api';
import { ErrorAlert } from '../Popups/ErrorAlert';

export default function GoogleAutocompleteInput() {
    const { startApiOperation, terminateApiOperation } = useApiOperation()
    const dispatch = useDispatch()
    const {
        value,
        setValue,
        suggestions: { data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['us', 'ca'] }
        }
    });

    const nav = useNavigate()
    const { data: userData } = useUser()
    const [error, setError] = useState('')
    const [disableInput, setDisableInput] = useState(false)

    const onClickAddress = async (description) => {
        setValue(description, false);
        clearSuggestions()

        const results = await getGeocode({ address: description })
        const { lat, lng } = await getLatLng(results[0])
        dispatch(setMapCenter({ lat, lng }))
        onClickSearch(description)
    }

    const onClickSearch = async (description) => {
        try {
            startApiOperation()
            const requestParams = {
                address: value,
                user: userData?.user?._id
            }
            const { isActive } = await JobsApi.isJobActive(requestParams)
            if (isActive) {
                dispatch(setJobInProgress(isActive))
                nav('/job')
            } else {
                if (value) {
                    dispatch(setAddress(description))
                    dispatch(setStep(2))
                    setDisableInput(true)
                }
                else {
                    setError('Address cannot be empty.')
                }
            }
            terminateApiOperation()
        } catch (error) {
            terminateApiOperation(['Something went wrong. Please try again later.'])
        }
    }

    useEffect(() => {
        setError('')
    }, [value])

    return (
        <>
            <label htmlFor="google-input" className='text-gray-500 block text-center text-lg'>Search which address you would like to start a job for</label>
            <div className='px-5'>
                <div className='flex justify-center w-full '>
                    <div className="w-full max-w-xl relative">
                        <input type="text" id='google-input' className='border border-gray-300 rounded-md px-4 py-3 mt-1 w-full focus-visible:outline-none h-14'
                            value={value}
                            disabled={disableInput}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {data.length > 0 && <div className="bg-white rounded-b-xl border divide-y absolute w-full">
                            {data.map(({ place_id, description }) => (
                                <div key={place_id}
                                    className="py-2 px-2 text-sm cursor-pointer hover:bg-gray-100"
                                    onClick={() => onClickAddress(description)}
                                >
                                    {description}
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>
                {error &&
                    <div className='max-w-xl mx-auto mt-3'>
                        <ErrorAlert errorMessages={error} />
                    </div>
                }
            </div>
        </>
    )
}

