export const mergeClasses = (...classes) => {
  return classes.filter(Boolean).join(' ');
}

export const removePTags = (stringWithPTags) => {
  return stringWithPTags.replace(/<\/?p>/g, '')
}


export const getValuesFromParams = (searchParams, option) => {
  const selections = searchParams.get(option) || "";
  return selections.split(",").filter(Boolean);
}



// Function to get start of the day (00:00:00)
export const getStartOfDay = (date) => {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  return start;
};

// Function to get end of the day (23:59:59)
export const getEndOfDay = (date) => {
  const end = new Date(date);
  end.setHours(23, 59, 59, 999);
  return end;
};

export const formatDateRange = (start, end) => {
  return `${start.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} - ${end.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}`;
};