import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotifications } from '../../features/notificationsSlice'
import { useApiOperation } from '../../hooks/useApiOperation'
import { HardwaresApi } from '../../lib/hardwares.api'
import { NOTIFY_TYPE } from '../../utils/constants'
import { PrimaryButton } from '../Button/PrimaryButton'
import { PopupBox } from '../Popups/PopupBox'
import { AddEditForm } from './addEditForm'


const initialHardwareState = {
  name: '',
  description: '',
  hwid: '',
  errors: [],
  dropdown: 'No'
}

const AddHardware = ({ refetch }) => {
  const [hardware, setHardware] = useState(initialHardwareState);
  const { description, hwid, name } = hardware
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const { startApiOperation, terminateApiOperation } = useApiOperation()

  const validateInputs = () => {
    const errors = [];
    if (!name) errors.push('Hardware name is required');
    if (!description) errors.push('Hardware description is required');
    if (!hwid) errors.push('Hardware ID is required');

    return errors;
  };

  const handleSubmit = async () => {
    try {
      startApiOperation()
      const errors = validateInputs();
      if (errors.length) return dispatch(setNotifications({ type: NOTIFY_TYPE.Error, messages: errors }));
      await HardwaresApi.addHardware(hardware);
      setOpenModal(false)
      setHardware(initialHardwareState)
      refetch()
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([
        'Something went wrong while adding new hardware. Please try again later',
      ]);
    }
  }

  return (
    <>
      <div className='flex justify-end mr-4 mt-2'>
        <PrimaryButton
          label='Add hardware'
          onClickEvent={() => setOpenModal(!openModal)}
          displayPlus
        />
      </div>
      <PopupBox modal={openModal} setModal={setOpenModal} handleClose={setOpenModal} title={'Add new hardware'} >
        <AddEditForm
          onSubmit={handleSubmit}
          hardware={hardware}
          setHardware={setHardware}
        />
      </PopupBox>
    </>
  )
}

export default AddHardware
