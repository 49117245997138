import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  GET_CHEMICALS: {
    path: '/chemicals/getChemicals',
    method: HTTP_METHODS.GET,
  },
  ADD_CHEMICAL: {
    path: '/chemicals/addChemical',
    method: HTTP_METHODS.POST,
  },
  UPDATE_CHEMICAL: {
    path: '/chemicals/updateChemical',
    method: HTTP_METHODS.PATCH,
  },
  DELETE_CHEMICAL: {
    path: '/chemicals/deleteChemical',
    method: HTTP_METHODS.DELETE,
  },
};



export const ChemicalsApi = {
  fetchChemicals: () => {
    return sendRequest(
      API_REQUESTS.GET_CHEMICALS.method,
      `${API_REQUESTS.GET_CHEMICALS.path}`
    );
  },
  addChemical: (data) => {
    return sendRequest(
      API_REQUESTS.ADD_CHEMICAL.method,
      `${API_REQUESTS.ADD_CHEMICAL.path}`,
      data
    );
  },
  updateChemical: (chemical) => {
    return sendRequest(
      API_REQUESTS.UPDATE_CHEMICAL.method,
      `${API_REQUESTS.UPDATE_CHEMICAL.path}`,
      chemical
    );
  },
  deleteChemical: (chemicalId) => {
    return sendRequest(
      API_REQUESTS.DELETE_CHEMICAL.method,
      API_REQUESTS.DELETE_CHEMICAL.path,
      { chemicalId }
    );
  },
};