import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const SearchBar = ({ handleSearchParams }) => {
  const [searchParams] = useSearchParams()
  const [input, setInput] = useState(searchParams.get('search') || '');

  return (
    <div className="flex max-w-[260px] items-center border h-fit border-gray-300 px-2 rounded-md focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500">
      <MagnifyingGlassIcon className="w-6 h-6 text-gray-400" />
      <input
        type="text"
        className="py-2 px-2 focus-visible:outline-none w-full"
        placeholder="Search"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearchParams('search', input)
          }
        }}
      />
    </div>
  )
}

export { SearchBar };

