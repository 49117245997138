import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApiOperation } from '../../hooks/useApiOperation';
import { getOwnerUser, getTechnicianByUser } from "../../hooks/useAuth";
import { formatDate } from '../../utils/formateDate';
import { Loader } from '../Loader/Loader';
import { ErrorAlert } from '../Popups/ErrorAlert';

export const JobDetails = ({ job }) => {
    const { startApiOperation, terminateApiOperation, apiError, apiLoading } = useApiOperation()
    const navigate = useNavigate();
    const [name, setName] = useState("");

    const getTechnicianName = async () => {
        try {
            startApiOperation()
            const { technician, ownerUserId, technicianUserId } = job
            if (!technician) {
                const { data: owner } = await getOwnerUser(ownerUserId);
                terminateApiOperation()
                return owner?.username;
            } else {
                const { data: technician } = await getTechnicianByUser(technicianUserId);
                terminateApiOperation()
                return technician?.technicianId?.username;
            }
        } catch (error) {
            console.error('An error occured while getting technician name', error)
            terminateApiOperation(['An error occured while getting technician name'])
        }
    };

    useEffect(() => {
        if (job) {
            getTechnicianName()
                .then((response) => setName(response))
                .catch((err) => {
                    console.error("Error fetching technician name:", err);
                    setName("Unknown");
                });
        }
    }, [job]);

    const handleBack = (propertyId) => {
        navigate(`/mapview/jobs-on-property/${propertyId}`, { replace: true });
    };


    const ListChemicals = () => {
        if (!job.chemicals.length) return <p className="text-sm text-gray-500">N/A</p>

        return job.chemicals.map((chemical, index) => (
            <p className="flex justify-between items-center text-sm text-gray-500" key={index}>
                <span>{chemical.chemicalName}</span>
                <span>{job?.meta?.chemicalsData?.find(item => item.chemicalName === chemical.chemicalName)?.amountUsed?.toFixed(2)} gm</span>
            </p>
        ))

    }

    const HardwareInfo = () => {
        if (!job?.hardwares?.length) return

        return <div>
            <p className="font-medium text-gray-900">Hardwares Used</p>
            {job.hardwares.map((singleHardware, index) => (
                <p className="text-sm text-gray-500" key={index}>{singleHardware.name}</p>
            ))}
        </div>
    }

    const totalGalons = job.chemicals?.length > 0 ?
        (
            <p className="text-sm text-gray-500">{job?.meta?.totalSprayUsed?.toFixed(2)}</p>
        ) : (
            <p className="text-sm text-gray-500">N/A</p>
        )


    if (apiLoading) return <Loader />;
    if (apiError) return <ErrorAlert errorMessages={apiError} />;
    if (!job) return <ErrorAlert errorMessages="No job data found." />;

    return (
        <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3 h-[600px] mt-1">
            <div className="px-4 py-3 sm:rounded-lg sm:px-6">
                <button onClick={() => handleBack(job?.address?._id)} type="button" className="text-blue-700 hover:bg-blue-100 bg-blue-50 font-medium rounded-full text-sm p-2.5 inline-flex items-center">
                    <ArrowLeftIcon className="w-6 h-6" />
                </button>
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mt-2">
                    {job?.address?.address || "No Address Provided"}
                </h2>
                <div className="mt-6">
                    <div className="pb-8 space-y-4">
                        <div>
                            <p className="font-medium text-gray-900">Visit Date</p>
                            <p className="text-sm text-gray-500">{job.startTime ? formatDate(job.startTime) : "N/A"} Time</p>
                        </div>
                        <div>
                            <p className="font-medium text-gray-900">Technician</p>
                            <p className="text-sm text-gray-500">{name || "N/A"}</p>
                        </div>
                        <div>
                            <p className="font-medium text-gray-900">Total Gallons</p>
                            {totalGalons}
                        </div>
                        <div>
                            <p className="font-medium text-gray-900">Chemical Used</p>
                            <ListChemicals />
                        </div>
                        <HardwareInfo />
                        <div>
                            <p className="font-medium text-gray-900">Time on Property</p>
                            <p className="text-sm text-gray-500">{job.jobDuration || "N/A"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}
