import React from 'react'
import DropdownMenu from '../../components/Menus/DropdownMenu'


const DateRangeFilter = () => {
  return (
    <div className="flex max-w-[240px]">
      {/* TODO: We need to rename this shared filter */}
      <DropdownMenu />
    </div>
  )
}

export { DateRangeFilter }
