import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline'
import { SecondaryButton } from '../Button/SecondaryButton'
import { BookmarkIcon } from '@heroicons/react/24/solid'

export default function Card({ name, description, onEditClick, hwid, onDeleteClick, marker }) {
    return (
        <div className='col-span-1 rounded-lg bg-white shadow border border-gray-300 w-full min-h-[160px] flex flex-col'>
            <div className='px-4 pt-4 flex-grow'>
                <h3 className='font-semibold text-sm font-inter text-[#1A1C1C] leading-5 truncate'>{name}</h3>
                <span className='leading-6 hyphens-auto text-gray-500'>
                    <p dangerouslySetInnerHTML={{ __html: description }} className='text-sm mt-3'></p>
                    <p className='text-sm text-blue-500'>{hwid}</p>
                </span>
            </div>
            <div className='px-4 pb-4 flex-shrink-0'>
                <div className='mt-3 flex gap-x-3 md:gap-x-4 justify-start items-center'>
                    <SecondaryButton label='Edit' icon={<PencilIcon className='w-4 text-gray-500' />} onClick={onEditClick} />
                    <SecondaryButton label='Delete' icon={<TrashIcon className='w-4 text-gray-500' />} onClick={onDeleteClick} />
                    {marker && <BookmarkIcon className='w-7 text-blue-600' />}
                </div>
            </div>
        </div>
    )
}
