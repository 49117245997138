export const PAGE_LIMIT = 9

export const FILTER_LIST = [
  { name: 'Today' },
  { name: 'Yesterday' },
  { name: 'This Week' },
  { name: 'Previous Week' },
  { name: 'Last 7 Days' },
  { name: 'Last 15 Days' },
  { name: 'This Month' },
  { name: 'Previous Month' },
  { name: 'Last 30 Days' },
  { name: 'Last 60 Days' },
  { name: 'Last Year' },
];


export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const NOTIFY_TYPE = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
}


export const ButtonSizes = {
  EXTRASMALL: 'EXTRASMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRALARGE: 'EXTRALARGE',
  FULL: 'FULL',
  FIT: 'FIT',
}


export const ButtonVariants = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DANGER: 'DANGER',
  DISABLED: 'DISABLED',
  TABS: 'TABS',
  LINK: 'LINK',
  CLIPBOARD: 'CLIPBOARD',
  DETAILS: 'DETAILS',
}


export const ButtonTypeVariant = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  Reset: 'reset',
}


export const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS"


