import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import DropdownMenuHC from "../components/Menus/DropdownHC";
import GoogleAutocompleteInput from "../components/Start/GoogleAutocompleteInput";
import { setChemicals, setHardwares, setStep } from "../features/jobDetailsSlice";
import { setJobInProgress } from "../features/jobInProgressSlice";
import { useApiOperation } from "../hooks/useApiOperation";
import { useUser } from "../hooks/useAuth";
import { useCreateProperty } from "../hooks/useProperty";
import { ChemicalsApi } from "../lib/chemicals.api";
import { HardwaresApi } from "../lib/hardwares.api";
import { JobsApi } from "../lib/jobs.api";

// Keeping libraries array as a const outside component due to performance warning in console
const libraries = ["places"];

export default function Start() {
  const date = new Date();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [selectedChemicals, setSelectedChemicals] = useState([]);
  const [selectedHardwares, setSelectedHardwares] = useState([]);
  const [hardware, setHardware] = useState([])
  const [chemical, setChemical] = useState([])
  const [jobsData, setJobsData] = useState([])
  const jobDetails = useSelector((state) => state.jobDetails);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const { mutateAsync: createProperty } = useCreateProperty();
  const { data: userData } = useUser();


  useEffect(() => {
    fetchChemicals()
    fetchHardwares();
    searchJobs()
  }, []);

  useEffect(() => {
    if (!jobsData?.jobs?.length) return;
    const { jobs } = jobsData;
    const currentOpenJob = jobs[0];
    dispatch(setJobInProgress(currentOpenJob));
    nav("/job");
  }, [userData, jobsData]);


  useEffect(() => {
    dispatch(setChemicals(selectedChemicals));
  }, [selectedChemicals, dispatch]);

  useEffect(() => {
    dispatch(setHardwares(selectedHardwares));
  }, [selectedHardwares, dispatch]);

  const hardwareOptions = useMemo(() => {
    return hardware?.map((item) => ({
      id: item._id,
      name: item.name,
    }));
  }, [hardware]);

  const chemicalOptions = useMemo(() => {
    return chemical?.map((item) => ({
      id: item._id,
      name: item.chemicalName,
    }));
  }, [chemical]);

  const fetchHardwares = async () => {
    try {
      startApiOperation()
      const responseData = await HardwaresApi.fetchHardwares();
      setHardware(responseData);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation("An error occured while fetching hardwares");
    }
  };

  const fetchChemicals = async () => {
    try {
      startApiOperation()
      const responseData = await ChemicalsApi.fetchChemicals();
      setChemical(responseData);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation("An error occured while fetching chemicals");
    }
  };

  const searchJobs = async () => {
    try {
      startApiOperation()
      const requestBody = {
        page: 1,
        search: "",
        user:
          userData?.user?.role === "user"
            ? userData?.user?._id
            : userData?.user?.ownerUserId,
        technician:
          userData?.user?.role === "technician" ? userData?.user?._id : null,
        completeUser: userData?.user,
        role: userData?.user?.role,
        active: true,
      }
      const responseData = await JobsApi.searchJobs(requestBody)
      setJobsData(responseData)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation("An error occured while searching for jobs.");
    }
  };

  const handleStartJob = async () => {
    try {
      startApiOperation()
      const property = await createProperty({
        address: jobDetails.address,
        mapCenter: jobDetails.mapCenter,
      });
      const body = {
        address: property._id,
        chemicals: jobDetails.chemicals,
        hardwares: jobDetails.hardwares,
        ownerUserId:
          userData?.user.role === "user"
            ? userData?.user?._id
            : userData?.user?.ownerUserId,
        startTime: date,
        endTime: null,
        technician:
          userData?.user.role === "technician" ? userData?.user?._id : null,
      }
      const res = await JobsApi.createJob(body)
      dispatch(setJobInProgress(res));
      dispatch(setStep(2))
      nav("/job");
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation("An error occured while starting this job");
    }
  };

  const DropdownWithSelectionBox = ({
    dropdownOptions,
    selectedItems,
    setSelectedItems,
    placeholder,
    title,
    classNames,
    dropdownPosition,
    itemName,
  }) => {
    return (
      <div
        className={`w-full flex flex-col ${dropdownPosition === "right"
          ? "md:items-end lg:items-end"
          : "md:items-start lg:items-start"
          }`}
      >
        <DropdownMenuHC
          dropdownOptions={dropdownOptions}
          selector={selectedItems}
          setSelector={setSelectedItems}
          arrowDirectionUp={false}
          dropdownPosition={dropdownPosition}
          type="checkbox"
          placeholder={placeholder}
          classNames={classNames}
          itemName={itemName}
        />
        <div className="w-full sm:w-[100%] md:w-[90%] flex flex-col border rounded-t-xl py-4 mt-4 divide-y overflow-hidden">
          <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">
            {title}
          </h1>
          <div className="p-2 flex flex-col gap-2 overflow-y-auto max-h-[300px] h-[200px]">
            {selectedItems.length > 0 ? (
              selectedItems.map((item, index) => (
                <div
                  key={index}
                  className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2"
                >
                  {dropdownOptions.find((option) => option.id === item)?.name ||
                    item}
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-sm text-center">
                No items selected
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <div className="mt-10">{isLoaded && <GoogleAutocompleteInput />}</div>

      {jobDetails.step === 2 && (
        <>
          <h1 className="text-3xl text-center py-6 font-medium text-blue-600 pb-4">
            Chemicals & Hardwares
          </h1>
          <div className="w-full flex flex-col md:flex-row p-5 gap-4">
            <DropdownWithSelectionBox
              dropdownOptions={hardwareOptions}
              selectedItems={selectedHardwares}
              setSelectedItems={setSelectedHardwares}
              placeholder="Choose Single or Multiple Hardwares"
              title="Selected Hardwares"
              classNames=""
              dropdownPosition="left"
            />

            <DropdownWithSelectionBox
              dropdownOptions={chemicalOptions}
              selectedItems={selectedChemicals}
              setSelectedItems={setSelectedChemicals}
              placeholder="Choose Single or Multiple Chemicals"
              title="Selected Chemicals"
              classNames=""
              dropdownPosition="right"
              itemName="Chemicals"
            />
          </div>
          {jobDetails.chemicals.length > 0 &&
            jobDetails.hardwares.length > 0 && (
              <div className="flex w-full justify-center py-2">
                <button
                  className="bg-blue-600 text-white px-5 py-3 text-xl text-center rounded-md w-60 hover:bg-blue-800 transition-all tracking-wider"
                  onClick={handleStartJob}
                >
                  START JOB
                </button>
              </div>
            )}
        </>
      )}
    </DashboardLayout>
  );
}
