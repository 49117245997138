
import { CardList } from "../CardList/CardList";
import { DeletePopup } from "../Popups/DeletePopup";
import EditHardware from "./editHardware";

export const ListHardwares = ({ deleteSelected, deleteModal, setDeleteModal, editModal, setEditModal, hardwaresData, reFetch, setSelectedHardware, selectedHardware }) => {
  const handleEditClick = (hardware) => {
    setSelectedHardware({
      ...hardware,
      dropdown: hardware.defaultHardware ? 'Yes' : 'No'
    });
    setEditModal(true)
  }

  const handleDeleteClick = (hardware) => {
    setSelectedHardware(hardware)
    setDeleteModal(true)
  }

  return (
    <>
      <DeletePopup
        onDelete={deleteSelected}
        label='Delete Hardware'
        desc='Are you sure you want to delete this hardware?'
        open={deleteModal}
        setOpen={setDeleteModal}
      />
      <EditHardware
        reFetch={reFetch}
        selectedHardware={selectedHardware}
        setSelectedHardware={setSelectedHardware}
        openModal={editModal}
        setOpenModal={setEditModal}
      />
      <CardList
        data={hardwaresData}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteClick}
      />
    </>
  )
}
