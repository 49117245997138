import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

export const API_REQUESTS = {
  GET_HARDWARES: {
    path: '/hardware/getHardwares',
    method: HTTP_METHODS.GET,
  },
  ADD_HARDWARE: {
    path: '/hardware/addHardware',
    method: HTTP_METHODS.POST,
  },
  UPDATE_HARDWARE: {
    path: '/hardware/editHardware',
    method: HTTP_METHODS.PATCH,
  },
  DELETE_HARDWARE: {
    path: '/hardware/deleteHardware',
    method: HTTP_METHODS.DELETE,
  },
};



export const HardwaresApi = {
  fetchHardwares: () => {
    return sendRequest(
      API_REQUESTS.GET_HARDWARES.method,
      `${API_REQUESTS.GET_HARDWARES.path}`
    );
  },
  addHardware: (data) => {
    return sendRequest(
      API_REQUESTS.ADD_HARDWARE.method,
      `${API_REQUESTS.ADD_HARDWARE.path}`,
      data
    );
  },
  updateHardware: (hardware) => {
    return sendRequest(
      API_REQUESTS.UPDATE_HARDWARE.method,
      `${API_REQUESTS.UPDATE_HARDWARE.path}`,
      hardware
    );
  },
  deleteHardware: (hardwareId) => {
    return sendRequest(
      API_REQUESTS.DELETE_HARDWARE.method,
      API_REQUESTS.DELETE_HARDWARE.path,
      { hardwareId }
    );
  },
};