
import React from 'react'
import { Input } from '../Input/Input'
import DropdownMenuHC from '../Menus/DropdownHC'
import ReactQuillComponent from '../ReactQuillComponent'

const AddEditForm = ({ onSubmit, hardware, setHardware }) => {
  const { name, description, hwid, dropdown } = hardware

  const handleInputChange = (key, value) => {
    setHardware((prevHardware) => ({
      ...prevHardware,
      [key]: value,
    }));
  };


  return (
    <>
      <div className='mt-5'>
        <Input
          options={{
            label: 'name',
            name: "name",
            id: "name",
            value: name,
            onChange: (e) => handleInputChange('name', e.target.value),
            type: 'text'
          }}
        />
      </div>

      <div className='mt-5'>
        <p className='text-[14px]'>description</p>
        <ReactQuillComponent content={description} setContent={(value) => handleInputChange('description', value)} />
      </div>

      <div className='mt-5 flex gap-x-3'>
        <Input options={{
          label: 'hwid',
          name: "hwid",
          id: "hwid",
          value: hwid,
          onChange: (e) => handleInputChange('hwid', e.target.value),
          type: 'text'
        }} />
        <DropdownMenuHC
          label='Make Default?'
          dropdownOptions={[{ name: 'Yes' }, { name: 'No' }]}
          selector={dropdown}
          setSelector={(value) => handleInputChange('measurement', value)}
          arrowDirectionUp={true}
        />
      </div>

      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          disabled={false}
          className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </>
  )
}

export { AddEditForm }
