import { useDispatch, useSelector } from 'react-redux'
import Gear from '../../assets/Gear.svg'
import { setStep } from '../../features/jobDetailsSlice'
import { setJobInProgress } from '../../features/jobInProgressSlice'
import { useApiOperation } from '../../hooks/useApiOperation'
import { JobsApi } from '../../lib/jobs.api'

export default function JobInProgress() {
    const { startApiOperation, terminateApiOperation } = useApiOperation()
    const dispatch = useDispatch()
    const jobInProgress = useSelector(state => state.jobInProgress)
    const { _id, startTime } = jobInProgress.jobInProgressDoc
    const handleCompleteJob = async () => {
        try {
            startApiOperation()
            const jobEndTime = new Date()
            const data = await JobsApi.endJob({ id: _id, startTime: startTime, endTime: jobEndTime })
            dispatch(setJobInProgress(data))
            dispatch(setStep(3))
            terminateApiOperation()
        } catch (error) {
            terminateApiOperation('An error occured while ending this job. Please try again later')
        }
    }
    return (
        <div className="block mt-20 text-center">
            <img src={Gear} alt="job in progress" className="mx-auto" />
            <p className="text-4xl md:text-8xl font-light">Your job is in progress</p>
            <button className="bg-red-600 px-20 py-2 text-white font-medium text-lg rounded-md mt-10 hover:shadow-[0_0_20px_rgba(220,38,38,1)] transition-all duration-300"
                onClick={handleCompleteJob}>
                Complete Job
            </button>
        </div>
    )
}
