import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  SEARCH_JOBS: {
    path: '/jobs/searchJobs',
    method: HTTP_METHODS.GET,
  },
  CREATE_JOB: {
    path: '/jobs/createJob',
    method: HTTP_METHODS.POST,
  },
  LATEST_JOBS: {
    path: '/jobs/latestJobs',
    method: HTTP_METHODS.GET,
  },
  END_JOB: {
    path: '/jobs/endJob',
    method: HTTP_METHODS.PATCH,
  },
  JOBS_ON_PROPERTY: {
    path: '/jobs/jobsOnProperty',
    method: HTTP_METHODS.GET,
  },
  JOB_BY_ID: {
    path: '/jobs/jobById',
    method: HTTP_METHODS.GET,
  },
  IS_JOB_ACTIVE: {
    path: '/jobs/isJobActive',
    method: HTTP_METHODS.GET,
  },
  GET_UNIQUE_JOBS: {
    path: '/jobs/activeJobs',
    method: HTTP_METHODS.GET,
  },
};



export const JobsApi = {
  searchJobs: (params) => {
    return sendRequest(
      API_REQUESTS.SEARCH_JOBS.method,
      `${API_REQUESTS.SEARCH_JOBS.path}`,
      { params: params }
    );
  },
  createJob: (job) => {
    return sendRequest(
      API_REQUESTS.CREATE_JOB.method,
      `${API_REQUESTS.CREATE_JOB.path}`,
      job
    );
  },
  getLatestJobs: () => {
    return sendRequest(
      API_REQUESTS.LATEST_JOBS.method,
      `${API_REQUESTS.LATEST_JOBS.path}`
    );
  },
  getJobsOnProperty: (params) => {
    return sendRequest(
      API_REQUESTS.JOBS_ON_PROPERTY.method,
      `${API_REQUESTS.JOBS_ON_PROPERTY.path}`,
      { params: params }
    );
  },
  endJob: (data) => {
    return sendRequest(
      API_REQUESTS.END_JOB.method,
      `${API_REQUESTS.END_JOB.path}`,
      data
    );
  },
  getJobById: (jobId) => {
    return sendRequest(
      API_REQUESTS.JOB_BY_ID.method,
      `${API_REQUESTS.JOB_BY_ID.path}`,
      { params: jobId }
    );
  },
  isJobActive: (params) => {
    return sendRequest(
      API_REQUESTS.IS_JOB_ACTIVE.method,
      `${API_REQUESTS.IS_JOB_ACTIVE.path}`,
      { params: params }
    );
  },
  getUniqueJobs: (data) => {
    return sendRequest(
      API_REQUESTS.GET_UNIQUE_JOBS.method,
      `${API_REQUESTS.GET_UNIQUE_JOBS.path}`,
      { params: data }
    );
  },
};



// const getAllUniqueJobs = async ({ queryKey }) => {
//     const { selectedFilter, user, technician, hardwareId, startDate, endDate } = queryKey[1];
//     let start, end;
//     if (startDate && endDate) {
//         start = startDate;
//         end = endDate;
//     } else {
//         const timeRange = TimeDuration(selectedFilter);
//         start = timeRange.startDate;
//         end = timeRange.endDate;
//     }

//     try {
//         const role = technician ? 'technician' : 'user';
//         const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/activeJobs/${start || null}/${end || null}/${user || ''}/${hardwareId || null}/${role}`);
//         return data;
//     } catch (error) {
//         throw new Error('Error fetching jobs');
//     }
// };



// export const useGetAllUniqueJobs = ({ selectedFilter, user, technician, hardwareId, startDate, endDate }) => {
//     return useQuery(
//         ['filterByDate', { selectedFilter, user, technician, hardwareId, startDate, endDate }],
//         getAllUniqueJobs,
//         {
//             enabled: Boolean(user),
//             refetchOnWindowFocus: false,
//             retry: 1,
//             staleTime: 5 * 60 * 1000,
//         }
//     );
// };


