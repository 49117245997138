import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader/Loader';
import JobsTimeline from '../../../../components/MapView/JobsTimeline';
import { useApiOperation } from '../../../../hooks/useApiOperation';
import { useUser } from '../../../../hooks/useAuth';
import { JobsApi } from '../../../../lib/jobs.api';
import FiltersMapLayout from '../filters-map-layout';

const JobsOnProperty = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const [jobsData, setJobsData] = useState([]);
  const [propertyId, setPropertyId] = useState(id)
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const { data: userData } = useUser();


  useEffect(() => {
    fetchJobsOnProperty()
  }, []);

  const fetchJobsOnProperty = async () => {
    try {
      startApiOperation()
      const hardwareId = searchParams.get("hardwareId")
      const params = {
        propertyId: propertyId,
        user: userData?.user._id,
        hardwareId: hardwareId || null
      };
      const data = await JobsApi.getJobsOnProperty(params);
      setJobsData(data);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation(['An error occured while fetching jobs on property. Please try again later.'])
    }
  };

  if (apiLoading) return <Loader />


  return (
    <FiltersMapLayout propertyId={propertyId} jobsData={jobsData} setPropertyId={setPropertyId}  >
      <div className="bg-white rounded-lg md:w-1/4 w-full lg:w-2/5 min-w-[250px] mt-6 sm:mt-0 overflow-auto border">
        <JobsTimeline jobs={jobsData} />
      </div>
    </FiltersMapLayout>
  )
}

export default JobsOnProperty
