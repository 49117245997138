import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFirstMessage } from "../../features/notificationsSlice";
import { ButtonSizes, ButtonVariants, NOTIFY_TYPE } from "../constants";
import { mergeClasses } from "../helpers";
import { Button } from "./button";

const NotifyPopup = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { messages, type } = useSelector((state) => state.notifications.notifications);

  useEffect(() => {
    if (!messages.length) {
      const timeoutModal = setTimeout(() => {
        setShow(false);
      }, 500);
      return () => {
        clearTimeout(timeoutModal);
      };
    }
    if (messages.length) {
      setShow(true);
      const timeout = setTimeout(() => {
        dispatch(removeFirstMessage());
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [messages]);
  if (!messages || !type) return null;


  const notifyBgColors = {
    [NOTIFY_TYPE.Success]: '!bg-green-100',
    [NOTIFY_TYPE.Info]: 'bg-yellow-100',
    [NOTIFY_TYPE.Warning]: 'bg-orange-100',
    [NOTIFY_TYPE.Error]: 'bg-red-100',
    default: 'bg-white-100',
  };


  const notificationBackgroudColor = notifyBgColors[type] || notifyBgColors.default;


  const iconMap = {
    [NOTIFY_TYPE.Error]: <ExclamationCircleIcon className="h-6 w-6 text-danger" />,
    [NOTIFY_TYPE.Success]: <CheckCircleIcon className="h-6 w-6 text-primary" />,
    [NOTIFY_TYPE.Info]: <CheckCircleIcon className="h-6 w-6 text-orange-600" />,
    [NOTIFY_TYPE.Warning]: <CheckCircleIcon className="h-6 w-6 text-warning" />,
  };


  const headerMap = {
    [NOTIFY_TYPE.Error]: 'Error',
    [NOTIFY_TYPE.Success]: 'Success',
    [NOTIFY_TYPE.Info]: 'Info',
    [NOTIFY_TYPE.Warning]: 'Warning',
  };


  const renderIcons = () => {
    return iconMap[type];
  };


  const Header = () => {
    return <h1 className="font-bold capitalize">{headerMap[type]}</h1>;
  };


  const Messages = () => {
    if (!messages) {
      return null;
    }
    return messages.map((message, index) => (
      <p className="capitalize" key={index}>
        &#x2022; {message}
      </p>
    ));
  };


  if (!type || !messages?.length) return null;


  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-[999] flex items-start px-4 py-6 sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={show}
        >
          <div
            className={mergeClasses(
              'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg p-4 shadow-lg ring-1 ring-black ring-opacity-5',
              notificationBackgroudColor
            )}
          >
            <div className="flex items-start">
              <div className="flex-shrink-0">{renderIcons()}</div>
              <div className="ml-3 flex w-fit flex-1 flex-col gap-1 pt-0.5 text-sm">
                <Header />
                <Messages />
              </div>
              <Button
                buttonStyles="ml-4 p-0 rounded-full border-none shadow-none inline-flex rounded-md  hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                textStyles="text-gray-400"
                icon={<XMarkIcon aria-hidden="true" className="h-5 w-5" />}
                size={ButtonSizes.EXTRASMALL}
                variant={ButtonVariants.SECONDARY}
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
export { NotifyPopup };
