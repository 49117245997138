import { axiosProtected } from "./axios-interceptor"

export const sendRequest = async (
  method,
  path,
  requestData,
  contentType = 'application/json',
  params = {}
) => {
  try {
    const BASE_URL = process.env.REACT_APP_IP

    const config = {
      method: method,
      headers: {
        'Content-Type': contentType,
      },
      data: requestData,
      params: requestData?.params || params
    }
    const response = await axiosProtected(`${BASE_URL}${path}`, config)

    if (response?.data) {
      return response.data;
    } else {
      return { error: response?.message || 'Unknown error occurred' };
    }

  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
}
