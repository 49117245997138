import { useNavigate, useSearchParams } from 'react-router-dom';

const ClearFiltersButton = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const isFilterApplied = [...searchParams].some(([_, value]) => value);

  const clearQueryParams = () => {
    navigate(window.location.pathname, { replace: true });
  };

  if (!isFilterApplied) return null;
  return (
    <div className="flex items-center justify-center">
      <button
        onClick={clearQueryParams}
        className="border-red-500 text-sm text-red-500 flex p-2 cursor-pointer rounded-lg border border-dashed"
      >
        Clear Filters
      </button>
    </div>
  );
};

export { ClearFiltersButton };
