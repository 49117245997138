import { Menu } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatDateRange } from "../../utils/helpers";
import DateFilter from "./DateFilter";

export default function DropdownMenu() {
  const [recentSelection, setRecentSelection] = useState("Today");
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultButtonStyles = `inline-flex w-full justify-between text-sm text-gray-900 pl-4 py-2.5 cursor-pointer gap-6 w-[200px]`;

  const CalenderButton = () => {
    return (
      <div
        onClick={() => setIsCalenderOpen((prev) => !prev)}
        className="ml-2 rounded-r-md cursor-pointer px-2 py-2.5 bg-gray-100 hover:bg-gray-200"
      >
        <CalendarIcon className="h-5 w-5 text-gray-600 " />
      </div >
    );
  };


  useEffect(() => {
    const startDateStr = searchParams.get('startDate');
    const endDateStr = searchParams.get('endDate');

    if (startDateStr && endDateStr) {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        
        setRecentSelection(formatDateRange(startDate, endDate));
    }
}, [searchParams]);



  const DisplayCalender = () => {
    if (!isCalenderOpen) return

    return <DateFilter
      setRecentSelection={setRecentSelection}
      setIsCalendarOpen={setIsCalenderOpen}
    />
  }

  return (
    <div className="w-full flex">
      <div className="relative w-full inline-block text-left">
        <Menu as="div" className="relative inline-block">
          <div className="flex w-full justify-between items-center rounded-md bg-white text-sm text-gray-900 border border-gray-300">
            <Menu.Button
              className={defaultButtonStyles}
              onClick={() => setIsCalenderOpen((prev) => !prev)}
              style={{ width: "100%" }}
            >
              <div className="text-[14px] flex-grow-1 whitespace-nowrap flex w-full justify-between">
                <span>{recentSelection}</span>
              </div>
            </Menu.Button>
            <CalenderButton />
          </div>
        </Menu>
        <DisplayCalender />
      </div>
    </div>
  );
}