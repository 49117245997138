import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './app/store';
import ModalVideoComponent from './components/ModalVideoComponent';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { NotifyPopup } from './utils/shared/notifications';
const rootElement = document.getElementById("root");
const root = createRoot(rootElement)

root.render(
    <QueryClientProvider client={new QueryClient()}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <NotifyPopup />
                <App />
                <ModalVideoComponent />
            </PersistGate>
        </Provider>
    </QueryClientProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
